import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Wheel from "../components/wheel"
import { graphql } from "gatsby"

const IndexPage = ({ data }) => {
  const foods = data?.allStrapiFoods?.nodes
  return (
    <Layout>
      <Seo title="Home" />
      <Wheel items={foods} />
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  {
    allStrapiFoods {
      nodes {
        slug
        name
        hexcode
        categories {
          name
        }
      }
    }
  }
`
