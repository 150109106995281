import React, { useState } from "react"
import JSONData from "../../content/food.json"
import SpinningWheel from "react-spinning-wheel"
import { isEmpty, random } from "lodash"
import { RandomReveal } from "react-random-reveal"
import { Modal as BootstrapModal } from "react-bootstrap"

const Wheel = ({ items }) => {
  const [modalShow, setModal] = useState(false)
  const [food, setFood] = useState({})
  const { Header, Title, Body } = BootstrapModal
  const foods =
    !isEmpty(items) && items.length >= 10 ? items : JSONData.content.foods
  const segments = foods.map(food => food.name)
  const segColors = foods.map(food => `#${food.hexcode}`)

  const onFinished = item => {
    const selectedFood = foods.find(food => food.name === item)
    setFood(selectedFood)
    setModal(true)
  }

  const onHide = () => setModal(false)

  return (
    <div className="content-container">
      <BootstrapModal
        onHide={onHide}
        show={modalShow}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Header className="text-center align-self-center">
          <Title>Kain na!</Title>
        </Header>
        <Body className="text-center p-5">
          <h2 className="w-100 d-flex align-items-center justify-content-center">
            {!isEmpty(food.categories) && (
              <div className="d-flex align-items-center justify-content-center">
                <RandomReveal
                  isPlaying
                  duration={4}
                  revealDuration={0.5}
                  revealEasing="random"
                  characters={
                    food.categories[random(0, food.categories.length - 1)].name
                  }
                />
                <div>&nbsp;</div>
                <div>&nbsp;</div>
              </div>
            )}
            <div>{food.name}</div>
          </h2>
        </Body>
      </BootstrapModal>
      <SpinningWheel
        options={segments}
        optColors={segColors}
        onFinished={winner => onFinished(winner)}
        primaryColor="#309630"
        contrastColor="#d7ffd7"
        buttonText="Spin"
        isOnlyOnce={false}
        size={280}
        upDuration={random(99, 111)}
        downDuration={random(111, 222)}
        fontFamily="proxima-nova"
      />
    </div>
  )
}

export default Wheel
